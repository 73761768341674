import { Locale } from "../interfaces/Locale";
import { AllowedPolygonColor } from "../interfaces/Sectors";

export const PolygonColors: AllowedPolygonColor[] = [
  "lightblue",
  "lightcyan",
  "lightgoldenrodyellow",
  "lightgray",
  "lightgreen",
  "lightpink",
  "lightsalmon",
  "lightseagreen",
  "lightslategrey",
  "lightsteelblue",
  "lightyellow",
];

export enum PolygonInteractions {
  SELECTED = "darkblue",
  UNSELECTED = "cornflowerblue",
  MOUSEOVER = "darkblue",
  SECONDARY_SELECTED = "darkcyan"
};

type LocalityConfig = {
  center: { lng: number; lat: number };
  zoom: number;
}

export const LOCALITY_CONFIG: Record<Locale, Record<string, LocalityConfig>> = {
  cl: {
    "Región de Antofagasta": {
      center: {
        lat: -23.61814,
        lng: -70.38554,
      },
      zoom: 12, 
    },
    "Capital": {
      center: {
        lat: -33.44548,
        lng: -70.64903,
      },
      zoom: 10,
    },
    "Región del Biobío": {
      center: {
        lat: -36.77925,
        lng: -73.13304,
      },
      zoom: 11,
    },
    "Región de Coquimbo": {
      center: {
        lat: -29.93487,
        lng: -71.28395,
      },
      zoom: 12,
    },
    "Región de Valparaíso": {
      center: {
        lat: -32.97625,
        lng: -71.46122,
      },
      zoom: 10.75,
    },
  },
  mx: {
    "Capital": {
      center: {
        lat: 19.45435,
        lng: -99.1201,
      },
      zoom: 10,
    },
  },
};
