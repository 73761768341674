import React, { useEffect, useState } from "react";

import { navigate } from "gatsby";
import Swal from "sweetalert2";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";

import { SpacedPaper, SectionHeader } from "../../Containers/SpacedPaper";
import Flex from "../../Containers/Flex";
import Bold from "../../Typography/Bold";
import nurseService from "../../../utils/api/v1/nurseService";
import { medicalActionAPI } from '../../../utils/api/v2';
import { capitalize } from "../../../utils/texts";

import type { Nurse, Specialty } from "../../../utils/interfaces/Nurse";
import type { Locale } from "../../../utils/interfaces/Locale";
import type { MedicalAction } from "../../../utils/interfaces/Services";

function getTranslations(category) {
  const traductions = {
    blood: "Sangre",
    urine: "Orina",
    vaccine: "Vacuna",
    covid: "Covid",
    kine: "Kine",
    electrocardiogram: "ECG",
    nutri: "Nutrición",
    "calcio iónico": "Calcio iónico",
    logistics: "Logísticos",
    pharyngeal: "Faríngeo",
    other: "Otro",
  };
  return traductions[category] || capitalize(category);
}

type HeaderProps = {
  editing:  boolean;
  nurseData: Nurse;
  handleCancel: () => void;
  handleActivate: () => void;
  handleUpdateNurse: () => void;
  onSetEditing: (value: boolean) => void;
};

const Header = ({
  editing,
  nurseData,
  handleCancel,
  handleActivate,
  handleUpdateNurse,
  onSetEditing,
}: HeaderProps) => {
  return (
    <SectionHeader>
      <Bold>Datos personales</Bold>
      {editing ? (
        <ButtonGroup variant="text">
          <Button color="secondary" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleUpdateNurse}>
            Actualizar
          </Button>
        </ButtonGroup>
      ) : (
        <ButtonGroup variant="text">
          {nurseData.active ? (
            <Button color="secondary" onClick={handleActivate}>
              Desactivar
            </Button>
          ) : (
            <Button color="primary" onClick={handleActivate}>
              Activar
            </Button>
          )}
          <Button
            color="primary"
            onClick={() => {
              onSetEditing(true);
            }}
          >
            Editar
          </Button>
          <Button
            color="primary"
            onClick={() =>
              navigate(`/health-team/${nurseData.id}/exams/`, {
                state: nurseData,
              })
            }
          >
            Detalle exámenes
          </Button>
        </ButtonGroup>
      )}
    </SectionHeader>
  )
};


type NurseInformationProps = {
  nurseData: Nurse;
  labsAndNExams: Object;
  servicesCategories: string[];
  onSetNurseData: (nurseData: Nurse) => void;
  onUpdateSpecialties: (newValues: string[]) => void;
}

const NurseInformation = ({
  nurseData,
  labsAndNExams,
  servicesCategories,
  onSetNurseData,
  onUpdateSpecialties,
}: NurseInformationProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [nurseInitialData, setNurseInitialData] = useState<Nurse>(nurseData);
  const [specialtyOptions, setSpecialtyOptions] = useState<string[]>([]);
  const [medicalActions, setMedicalActions] = useState<MedicalAction[]>([]);

  useEffect(() => {
    const fetchNurseSpecialties = async () => {
      const res = await nurseService.fetchNurseSpecialties();
      const specialties = res.data.map((speciality: Specialty) => speciality.name);
      setSpecialtyOptions(specialties);
    };
    fetchNurseSpecialties();
    setNurseInitialData(nurseData);
  }, []);

  useEffect(() => {
    if (!nurseData) return;

    medicalActionAPI.list(nurseData.country as Locale)
      .then(response => setMedicalActions(response.data))
      .catch(console.log);
  }, [nurseData]);

  const updateNurse = async () => {
    setLoading(true);
    const res = await nurseService.updateNurse(
      nurseData.id,
      {
        fingerprint_available: nurseData.fingerprint_available,
        specialties: nurseData.specialties,
        fake: nurseData.fake,
      },
      true,
    );
    const data = res.data.data;
    onSetNurseData({
      ...data,
      specialties: data.specialties.map((speciality: Specialty) => speciality.name),
    });
    setEditing(false);
    setLoading(false);
  };

  const toggleActivate = async () => {
    const check = await Swal.fire({
      title: "¿Estás seguro?",
      icon: "warning",
      showCancelButton: true,
    });
    if (!check.isConfirmed) return;
    setLoading(true);
    await nurseService.activateNurse(nurseData.id, {
      active: !nurseData.active,
    });
    onSetNurseData({ ...nurseData, active: nurseData.active! });
    setLoading(false);
  };

  const handleCancel = () => {
    onSetNurseData(nurseInitialData);
    setEditing(false);
  };

  const renderSelectedOptions = (selected: string[] | string | any) => {
    return (
      <Flex wrap="wrap">
        {(selected as string[]).map((value) => (
          <Chip variant="outlined" color="primary" key={value} label={value} />
        ))}
      </Flex>
    );
  };

  return (
    <SpacedPaper variant="outlined">
      <Flex direction="column">
        <Header
          nurseData={nurseData}
          editing={editing}
          onSetEditing={(value: boolean) => setEditing(value)}
          handleActivate={toggleActivate}
          handleCancel={handleCancel}
          handleUpdateNurse={updateNurse}
        />
        <hr />
        {loading && (
          <Flex justify="center" align="center" padding="3rem">
            <CircularProgress />
          </Flex>
        )}
        {!loading && (
          <div className="w-full flex flex-col gap-y-4 px-6 py-3">
            <div className="w-full flex flex-col">
              <div className="w-full grid grid-cols-12">
                <p className="col-span-3 font-medium">Nombre:</p>
                <p className="col-span-9">{nurseData.names} {nurseData.last_names}</p>
              </div>
              <div className="w-full grid grid-cols-12">
                <p className="col-span-3 font-medium">Email:</p>
                <p className="col-span-9">{nurseData.email}</p>
              </div>
              {nurseData.rut && (
                <div className="w-full grid grid-cols-12">
                  <p className="col-span-3 font-medium">Rut:</p>
                  <p className="col-span-9">{nurseData.rut}</p>
                </div>
              )}
              <div className="w-full grid grid-cols-12">
                <p className="col-span-3 font-medium">Teléfono:</p>
                <p className="col-span-9">{nurseData.phone}</p>
              </div>
            </div>
            <div className="w-full flex justify-start items-center gap-4">
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editing}
                    name="¿Tiene huellero?"
                    color="primary"
                    checked={nurseData.fingerprint_available}
                    onChange={(e) => {
                      onSetNurseData({
                        ...nurseData,
                        fingerprint_available: e.target.checked,
                      });
                    }}
                  />
                }
                label="¿Tiene huellero?"
              />
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editing}
                    name="Cuenta Falsa"
                    color="primary"
                    checked={nurseData.fake}
                    onChange={(e) => {
                      onSetNurseData({
                        ...nurseData,
                        fake: e.target.checked,
                      }); 
                    }}
                  />
                }
                label="Cuenta Falsa"
              />
            </div>
            <div className="w-full flex flex-col items-start gap-y-2">
              <p className="font-bold text-xl">Servicios</p>
              <div className="flex flex-wrap gap-2">
                {servicesCategories.map((category) => (
                  <Chip key={category} variant="outlined" color="primary" label={getTranslations(category)} />
                ))}
              </div>
            </div>
            <div className="w-full flex flex-col items-start gap-y-2">
              <p className="font-bold text-xl">Especialidades</p>
              {!editing && (
                <div className="flex flex-wrap gap-2">
                  {!specialtyOptions.length && "No tiene especialidades"}
                  {specialtyOptions.map((speciality: string) => (
                    <Chip variant="outlined" color="primary" key={speciality} label={speciality} />
                  ))}
                </div>
              )}
              {editing && (
                <Select
                  multiple
                  fullWidth
                  value={specialtyOptions}
                  onChange={(event) => {
                    onUpdateSpecialties(event.target.value as string[]);
                    setSpecialtyOptions(event.target.value as string[]);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={renderSelectedOptions}
                >
                  {specialtyOptions?.map((specialty) => (
                    <MenuItem key={specialty} value={specialty}>
                      {specialty}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>
            <div className="w-full flex flex-col items-start gap-y-2">
              <p className="font-bold text-xl">Laboratorios</p>
              <div className="flex flex-col">
                {!Object.entries(labsAndNExams).length && "No esta asignado a laboratorios"}
                <ul className="list-disc">
                  {Object.entries(labsAndNExams).map((item) => (
                    <li>{item[0]} ({item[1]})</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="w-full flex flex-col items-start gap-y-2">
              <p className="font-bold text-xl">Acciones Médicas</p>
              <div className="flex flex-wrap gap-2">
                {medicalActions.map((medicalAction) => (
                  <Chip key={medicalAction.id} variant="outlined" color="primary" label={medicalAction.name} />
                ))}
              </div>
            </div>
          </div>
        )}
      </Flex>
    </SpacedPaper>
  );
};

export default NurseInformation;
