import api from "../api";
import { Locale } from "../../interfaces/Locale";
import { Sector } from "../../interfaces/Sectors";
import { Nurse } from "../../interfaces/Nurse";

class SectorsAPI {
  list = async (country: Locale): Promise<Sector[] | undefined> => {
    try {
      const res = await api.get<Sector[]>(`v2/sector/`, {
        params: { country },
      });
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  fetchSectorNurses = async (sectorId: string): Promise<Nurse[] | undefined> => {
    try {
      const res = await api.get<Nurse[]>(`v2/sector/${sectorId}/sector-nurses/`);
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  fetchNurseSectors = async (nurseId: string): Promise<Sector[] | undefined> => {
    try {
      const res = await api.get<Sector[]>(`v2/sector/nurse-sectors/`, {
        params: { nurse_id: nurseId },
      });
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  assignNurseToSector = async (sectorId: string, nurseId: string): Promise<{msg: string} | undefined> => {
    try {
      const res = await api.post<{msg: string}>(`v2/nurse-sector-timeblocks/assign/`, {
        sector_id: sectorId,
        nurse_id: nurseId,
      });
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  unassignNurseToSector = async (sectorId: string, nurseId: string): Promise<{msg: string} | undefined> => {
    try {
      const res = await api.post<{msg: string}>(`v2/nurse-sector-timeblocks/unassign/`, {
        sector_id: sectorId,
        nurse_id: nurseId,
      });
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  manualUpsert = async (data: FormData): Promise<{msg: string} | undefined> => {
    try {
      const res = await api.post<{msg: string}>(`v2/sector/manual-upsert/`, data);
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  forceUpsert = async (country: Locale): Promise<{msg: string} | undefined> => {
    try {
      const res = await api.post<{msg: string}>(`v2/sector/force-upsert/`, { country });
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  blockItemSector = async (sectorId: string, itemId: string): Promise<any> => {
    try {
      const res = await api.post(`v2/blocked-item-sector/`, {
        sector: sectorId,
        item: itemId,
      });
      return res.data;
    } catch (error) {
      return undefined;
    }
  };

  unblockItemSector = async (sectorId: string, itemId: string): Promise<any> => {
    try {
      const res = await api.post(`v2/blocked-item-sector/remove-blockage/`, {
        sector_id: sectorId,
        item_id: itemId,
      });
      return res.data;
    } catch (error) {
      return undefined;
    }
  };
}

const sectorsAPI = new SectorsAPI();

export default sectorsAPI;
