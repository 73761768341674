import React from "react";
import clsx from "clsx";

import { CODE_TO_COUNTRY, COUNTRY_CODES } from "../../utils/data/constants";

import { Locale } from "../../utils/interfaces/Locale";

type CountrySelectProps = {
  onSetCountry: (country: Locale) => void;
}

const CountrySelect = ({ onSetCountry }: CountrySelectProps) => {
  return (
    <div className="w-full">
      <h2>Selecciona un país para visualizar sus sectores</h2>
      <div className="mt-20 w-full flex items-center justify-center">
        {COUNTRY_CODES.map((country) => (
          <div key={country} className={clsx(
            "w-48 h-48",
            "mx-8 p-10",
            "flex items-center justify-center",
            "bg-sky-200 rounded-md hover:scale-110 transition-all duration-200",
            "cursor-pointer",
            "text-2xl",
          )}
          onClick={() => onSetCountry(country)}
          >
            {CODE_TO_COUNTRY[country]}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CountrySelect;
