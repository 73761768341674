import React, { useState, useEffect, useCallback, useRef } from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import clsx from "clsx";

import {
  LeftHeader,
  RightHeader,
  Polygon,
  CountrySelect,
  Nurses,
  NurseDetail,
  Upserter,
  LandmarkSelect,
  Items,
} from "../../components/Sectors";

import sectorsAPI from "../../utils/api/v2/places";
import nurseService from "../../utils/api/v1/nurseService";
import { LOCALITY_CONFIG } from "../../utils/constants/sectors";

import type { Locale } from "../../utils/interfaces/Locale";
import type { Sector } from "../../utils/interfaces/Sectors";
import type { Nurse } from "../../utils/interfaces/Nurse";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const SectorsPage = () => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [allNurses, setAllNurses] = useState<Nurse[]>([]);
  const [selectedSector, setSelectedSector] = useState<Sector | null>(null);
  const [selectedSectorId, setSelectedSectorId] = useState<string | null>(null);
  const [selectedNurseId, setSelectedNurseId] = useState<string | null>(null);
  const [showAllNurseSectors, setShowAllNurseSectors] = useState<boolean>(false);
  const [highlightedSectorsIds, setHighlightedSectorsIds] = useState<string[]>([]);
  const [country, setCountry] = useState<Locale | null>(null);
  const [selectedLandmark, setSelectedLandmark] = useState<string>("Capital");
  const { isLoaded } = useLoadScript({ googleMapsApiKey: "AIzaSyBBJmRxPoN3N6L5F8MVlUsRBID9_TsM90w" });
  const scrollableContainerRef = useRef<HTMLDivElement | null>(null);

  const resetHighlights = () => {
    setShowAllNurseSectors(false);
    setHighlightedSectorsIds([]);
  };

  const reset = () => {
    setSelectedSector(null);
    setSelectedNurseId(null);
    setSelectedSectorId(null);
    resetHighlights();
  };

  const handleOnSelectSector = (id: string) => {
    setSelectedSectorId(id);
    const foundSector = sectors.find((sector) => sector.id === id);
    setSelectedSector(foundSector || null);
  };

  const handleOnSelectNurse = (id: string) => {
    if (selectedNurseId === id) {
      setSelectedNurseId(null);
    } else {
      setSelectedNurseId(id);
      scrollableContainerRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const fetchSectors = useCallback(async () => {
    if (!country) return;
    const sectors = await sectorsAPI.list(country);
    if (sectors) {
      setSectors(sectors);
    }
  }, [country]);

  useEffect(() => {
    if (selectedNurseId === null && showAllNurseSectors) resetHighlights();
  }, [showAllNurseSectors, selectedNurseId]);

  useEffect(() => {
    if (showAllNurseSectors && selectedNurseId && map && country) {
      const fetchNurseSectors = async () => {
        const nurseSectors = await sectorsAPI.fetchNurseSectors(selectedNurseId);
        if (nurseSectors) {
          map.setZoom(LOCALITY_CONFIG[country][selectedLandmark].zoom);
          map.setCenter(LOCALITY_CONFIG[country][selectedLandmark].center);
          setHighlightedSectorsIds(nurseSectors.map((sector) => sector.id));
        }
      };
      fetchNurseSectors();
    }
  }, [showAllNurseSectors, selectedNurseId, map, country]);

  useEffect(() => {
    // Handle country change
    if (!country) return;

    const fetchAllNurses = async () => {
      const activeRes = await nurseService.fetchActiveNurses(country);
      const inactiveRes = await nurseService.fetchInactiveNurses(country);
      let allNurses = [];
      if (activeRes) {
        allNurses = allNurses.concat(activeRes.data.data);
      }
      if (inactiveRes) {
        allNurses = allNurses.concat(inactiveRes.data.data);
      }
      setAllNurses(allNurses);
    };
    fetchAllNurses();
    fetchSectors();
    reset();
  }, [country]);

  if (!isLoaded) return null;

  if (!country) return <CountrySelect onSetCountry={(country: Locale) => setCountry(country)} />;

  return (
    <div className="w-full grid grid-cols-12 gap-x-3">
      <div className="h-[calc(100vh_-_20rem)] w-full col-span-5">
        <LeftHeader
          country={country}
          sector={selectedSector}
          onSetCountry={(country: Locale) => setCountry(country)}
        />
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={LOCALITY_CONFIG[country][selectedLandmark].center}
          zoom={LOCALITY_CONFIG[country][selectedLandmark].zoom}
          onLoad={(map) => setMap(map)}
        >
          {sectors?.map((sector) => (
            <Polygon
              key={sector.id}
              sector={sector}
              selectedId={selectedSectorId}
              country={country}
              selectedLandmark={selectedLandmark}
              isHighlighted={highlightedSectorsIds.includes(sector.id)}
              forceHighlight={showAllNurseSectors}
              onSelect={handleOnSelectSector}
            />
          ))}
        </GoogleMap>
        <Upserter country={country} fetchSectorsCallback={fetchSectors} />
      </div>
      <div
        className={clsx(
          "relative",
          "h-[calc(100vh_-_13rem)] w-full px-10 pb-5",
          "col-span-7 flex flex-col gap-y-10",
          "overflow-y-scroll",
        )}
      >
        <LandmarkSelect
          country={country}
          currentLandmark={selectedLandmark}
          onSetLandmark={(landmark: string) => setSelectedLandmark(landmark)}
        />
        {!selectedSector && (
          <h2 className="absolute w-full top-1/2 -translate-y-1/2 transform text-center font-normal">
            Selecciona un sector para administrarlo 🧐
          </h2>
        )}
        {!!selectedSector && (
          <>
            <RightHeader sector={selectedSector} />
            <Nurses
              allNurses={allNurses}
              selectedSectorId={selectedSectorId}
              selectedNurseId={selectedNurseId}
              onSelectNurse={handleOnSelectNurse}
            />
            <NurseDetail
              nurse={allNurses.find((nurse) => nurse.id === selectedNurseId) || null}
              onToggleShowAllNurseSectors={() => setShowAllNurseSectors(!showAllNurseSectors)}
            />
            {selectedSectorId && <Items selecteSectorId={selectedSectorId} />}
          </>
        )}
        <div ref={scrollableContainerRef} />
      </div>
    </div>
  )
};

export default SectorsPage;
