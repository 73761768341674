import React from "react";

import { Link, navigate } from "gatsby";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";


import PrivateRoute from "../../Authentication/PrivateRoute";
import LoadingError from "../../Loaders/LoadingError";
import Flex from "../../Containers/Flex";
import Bold from "../../Typography/Bold";
import DisplayTimeBlock from "../../Timeblocks/DisplayTimeBlock";
import DisplayBlockedTimeBlock from "../../Timeblocks/DisplayBlockedTimeBlock";
import { SpacedPaper, SectionHeader } from "../../Containers/SpacedPaper";

import type { GroupedTimeBlocks, GroupedBlockedTimeBlocks } from "../../../utils/interfaces/Timeblock";
import type { Nurse } from "../../../utils/interfaces/Nurse";


interface NurseTimeblocksProps {
  loading: boolean;
  error: any;
  nurseData: Nurse | null;
  activeTimeblocks: GroupedTimeBlocks;
  blockedTimeblocks: GroupedBlockedTimeBlocks;
}

const NurseTimeblocks = ({
  loading,
  error,
  nurseData,
  activeTimeblocks,
  blockedTimeblocks,
}: NurseTimeblocksProps) => {

  return (
    <PrivateRoute>
      <LoadingError loading={loading} error={error} />
      {!loading && nurseData && (
        <>
          <SpacedPaper variant="outlined">
            <Flex direction="column">
              <SectionHeader>
                <Bold>Horarios habilitados</Bold>
                <ButtonGroup variant="text">
                  <Button
                    color="primary"
                    onClick={() => navigate("/dashboard/")}
                  >
                    Ver agenda
                  </Button>
                  <Link
                    to={`/health-team/${nurseData.id}/schedule/`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button color="primary">Editar</Button>
                  </Link>
                </ButtonGroup>
              </SectionHeader>
              <hr />
              <DisplayTimeBlock timeblocks={activeTimeblocks} />
            </Flex>
          </SpacedPaper>
          <SpacedPaper variant="outlined">
            <Flex direction="column">
              <SectionHeader>
                <Bold>Horarios bloqueados</Bold>
                <Link
                  to={`/health-team/${nurseData.id}/block-schedule/`}
                  style={{ textDecoration: "none" }}
                >
                  <Button color="primary">Editar</Button>
                </Link>
              </SectionHeader>
              <hr />
              <DisplayBlockedTimeBlock timeblocks={blockedTimeblocks} />
            </Flex>
          </SpacedPaper>
        </>
      )}
    </PrivateRoute>
  );
};

export default NurseTimeblocks;
