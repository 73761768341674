import React, { useState, useEffect } from "react";

import Button from '@material-ui/core/Button';

import NurseTimeblocks from "./NurseTimeblocks";
import NurseInformation from "./NurseInformation";
import nurseService from "../../../utils/api/v1/nurseService";
import timeBlockService from "../../../utils/api/v1/timeblockService";
import { groupTimeBlocks, groupBlockedTimeBlocks } from "../../../utils/timeblocks/group";

import type { Nurse } from "../../../utils/interfaces/Nurse";
import type { GroupedTimeBlocks, GroupedBlockedTimeBlocks } from "../../../utils/interfaces/Timeblock";

type NurseDetailProps = {
  nurse: Nurse | null;
  onToggleShowAllNurseSectors: () => void;
};

const NurseDetail = ({ nurse, onToggleShowAllNurseSectors }: NurseDetailProps) => {
  const [viewingAllSectors, setViewingAllSectors] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [nurseData, setNurseData] = useState<Nurse | null>(null);
  const [activeTimeblocks, setActiveTimeblocks] = useState<GroupedTimeBlocks>({});
  const [blockedTimeblocks, setBlockedTimeblocks] = useState<GroupedBlockedTimeBlocks>({});
  const [labsAndNExams, setLabsAndNExams] = useState<Object>({});
  const [servicesCategories, setServicesCategories] = useState<string[]>([]);

  useEffect(() => {
    if (!nurse) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const responses = await Promise.all([
          nurseService.fetchNurseProfile(nurse.id),
          timeBlockService.fetchNurseTimeBlocks(nurse.id),
          nurseService.fetchLabsAndNExams(nurse.id),
          nurseService.fetchServicesCategories(nurse.id),
        ]);
        const [nurseRes, timeblocksRes, labsAndExamsRes, serviceCategoriesRes] = responses;
        setNurseData(nurseRes.data);
        setActiveTimeblocks(groupTimeBlocks(timeblocksRes.data.data.timeblocks));
        setBlockedTimeblocks(groupBlockedTimeBlocks(timeblocksRes.data.data.blocked) as GroupedBlockedTimeBlocks);
        setLabsAndNExams(labsAndExamsRes.data.data);
        setServicesCategories(serviceCategoriesRes.data.data);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };
    fetchData();
  }, [nurse]);

  useEffect(() => {
    if (!nurse) setViewingAllSectors(false);
  }, [nurse]);

  if (!nurse) return null;

  return (
    <div className="w-full flex flex-col gap-y-2">
      <h3>Información de {nurse.names}</h3>
      <div className="w-fit">
        {viewingAllSectors ? (
          <Button
            variant="contained"
            color="primary"
            style={{ color: "black" }}
            onClick={() => {
              setViewingAllSectors(false);
              onToggleShowAllNurseSectors();
            }}
          >
            Volver a sector seleccionado
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            style={{ color: "black" }}
            onClick={() => {
              setViewingAllSectors(true);
              onToggleShowAllNurseSectors();
            }}
          >
            Ver sus sectores
          </Button>
        )}
      </div>
      {!!nurseData && (
        <NurseInformation
          nurseData={nurseData}
          labsAndNExams={labsAndNExams}
          servicesCategories={servicesCategories}
          onSetNurseData={(data: Nurse) => setNurseData(data)}
          onUpdateSpecialties={(specialties: string[]) => {
            setNurseData({ ...nurseData, specialties })
          }}
        />
      )}
      <NurseTimeblocks
        loading={loading}
        error={error}
        nurseData={nurseData}
        activeTimeblocks={activeTimeblocks}
        blockedTimeblocks={blockedTimeblocks}
      />
    </div>
  )
}

export default NurseDetail;
