import React, { useState, useEffect, useRef } from 'react';

import { Polygon as GMPolygon, useGoogleMap } from "@react-google-maps/api";

import { PolygonColors, PolygonInteractions, LOCALITY_CONFIG } from "../../utils/constants/sectors";

import { Locale } from '../../utils/interfaces/Locale';
import { Sector, FillBrush, StrokeBrush } from "../../utils/interfaces/Sectors";

const getPseudoRandomColor = () => PolygonColors[Math.floor(Math.random() * PolygonColors.length)];

const commonOptions = {
  disableDoubleClickZoom: true,
  fullscreenControl: false,
  fullscreenControlOptions: null,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1
}

const strokeInitialValues = { opacity: 0.8, color: PolygonInteractions.UNSELECTED, weight: 1 }


type PolygonProps = {
  sector: Sector;
  selectedId: string | null;
  country: Locale;
  selectedLandmark: string;
  forceHighlight?: boolean;
  isHighlighted?: boolean;
  onSelect: (id: string) => void;
};

const Polygon = ({
  sector,
  selectedId,
  country,
  selectedLandmark,
  forceHighlight,
  isHighlighted,
  onSelect,
}: PolygonProps) => {
  const [selected, setSelected] = useState<boolean>(false);
  const [initialFillValues] = useState<FillBrush>({ opacity: 0.4, color: getPseudoRandomColor() });
  const [fill, setFill] = useState<FillBrush>(initialFillValues);
  const [stroke, setStroke] = useState<StrokeBrush>(strokeInitialValues);
  const panTimeout = useRef<NodeJS.Timeout | null>(null);
  const zoomTimeout = useRef<NodeJS.Timeout | null>(null);
  const map = useGoogleMap();
  const animationBaseDelayMs = 100;

  const handleMouseOver = (_event: google.maps.MapMouseEvent) => {
    if (!selected) {
      setStroke((prevValues) => ({...prevValues, color: PolygonInteractions.MOUSEOVER }));
    }
  };

  const handleMouseOut = (_event: google.maps.MapMouseEvent) => {
    if (!selected) {
      setStroke((prevValues) => ({...prevValues, color: PolygonInteractions.UNSELECTED }));
    }
  };

  useEffect(() => {
    if (forceHighlight) return;

    if (sector.id === selectedId) {
      const currentZoom = map?.getZoom() || 10;
      const hasOriginalZoomOrLess = currentZoom <= LOCALITY_CONFIG[country][selectedLandmark].zoom;
      // Animate map movement to focus that sector
      if (!hasOriginalZoomOrLess) {
        map?.setZoom(12.4);
      }
      panTimeout.current = setTimeout(() => map?.panTo({ lng: sector.centroid.lng, lat: sector.centroid.lat }), animationBaseDelayMs);
      zoomTimeout.current = setTimeout(() => map?.setZoom(12.5), animationBaseDelayMs * 7);
      // Enable sector markup
      setSelected(true);
      setStroke((prevValues) => ({ ...prevValues, color: PolygonInteractions.SELECTED, opacity: 1, weight: 2 }));
    } else {
      // Clear current timeouts to avoid unwanted animations
      if (panTimeout.current) clearTimeout(panTimeout.current);
      if (zoomTimeout.current) clearTimeout(zoomTimeout.current);
      // Disable the sector's markup
      setSelected(false);
      setStroke((prevValues) => ({
        ...prevValues,
        color: PolygonInteractions.UNSELECTED,
        opacity: strokeInitialValues.opacity,
        weight: strokeInitialValues.weight,
      }));
    }

    setFill(initialFillValues);
  }, [sector, selectedId, country, forceHighlight]);

  useEffect(() => {
    if (!forceHighlight) return;

    if (isHighlighted) {
      setStroke((prevValues) => ({
        ...prevValues,
        color: PolygonInteractions.SECONDARY_SELECTED,
        opacity: 1,
        weight: 2,
      }));
      setFill((prevValues) => ({ ...prevValues, color: PolygonColors[0], opacity: 0.6 }));
    } else {
      setStroke((prevValues) => ({ ...prevValues, color: PolygonInteractions.SECONDARY_SELECTED, weight: 1 }));
      setFill((prevValues) => ({ ...prevValues, opacity: 0 }));
    }
  }, [forceHighlight, isHighlighted])

  return (
    <GMPolygon
      paths={sector.polygon}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={() => onSelect(sector.id)}
      options={{
        ...commonOptions,
        fillOpacity: fill.opacity,
        fillColor: fill.color,
        strokeOpacity: stroke.opacity,
        strokeColor: stroke.color,
        strokeWeight: stroke.weight,
      }}
    />
  );
}

export default Polygon;
